"use strict";

import * as UIUtils from "../../ui_utils";
import ImplementationNeededError from "../implementation_needed_error";
import Promisable from "./promisable";
import { BrowserSessionStorage } from "./storage/browser_session_storage";
import MemoryCache from "./memory_cache";
import { Log, LOG_GROUP } from "../../../server/common/logger/common_log";

const Logger = Log.group(LOG_GROUP.Framework, "BaseObjectCache");


export const LOADING_PLEASE_WAIT = "Loading... please wait.";

export const inMemoryCache = {};
const loadingSemaphore = {};
const LOADING_STATUS = {
  IDLE: "IDLE",
  LOADING: "LOADING",
  LOADED: "LOADED",
};

/**
 * This is the base class for caches that keep various typeahead options and objects.
 *
 * NOTE: Many of the internal methods here take a type as an argument even though there's a this.type. That's because
 * the MultipleTypeaheadObjectCache needs to handle multiple types and calls them repeatedly with different types.
 *
 * @override
 */
export default class BaseObjectCache {

  static isStorageLoaded = false;

  /**
   * @param type {string} The type that will be loaded.
   */
  constructor(type) {
    this.type = type;

    // Bind the results method because it loses this context when called from an Ajax response.
    this.handleTypeaheadResultsFromServer = this.handleTypeaheadResultsFromServer.bind(this);
    this.defaultFailFunction = this.defaultFailFunction.bind(this);
    BaseObjectCache.getCacheStorageEngine();
  }

  static isObjectCacheStillLoading(options) {
    return !options || options.isLoading;
  }

  static getInitialOptions() {
    let options = [LOADING_PLEASE_WAIT];
    options.isLoading = true;
    return options;
  }

  static async clearStorage() {
    return BaseObjectCache.getCacheStorageEngine().clear();
  }

  static getCacheStorageEngine() {
    return BrowserSessionStorage.getInstance();
  }

  /**
   * @return {string} The name of the sessionStorage variable where this cache will be stored.
   */
  getCacheName() {
    throw new ImplementationNeededError();
  }

  /**
   * This should return an ID that represents whatever IDs the subtype constructor was initialized with.
   *
   * @return {number|string} an ID of some kind to represent the value or set of values cached.
   * @abstract
   * @protected
   */
  getId() {
    throw new ImplementationNeededError();
  }

  /**
   * @return {object} An object where the keys are ids (see getId()) and the values are functions that need to be called
   * once the data is loaded.
   * @abstract
   * @protected
   */
  // eslint-disable-next-line
  getIdToOnLoadedFunctions(type) {
    throw new ImplementationNeededError();
  }

  /**
   * @return {string} A URL to the server to get the information to be cached.
   * @abstract
   * @protected
   */
  buildURLForAjaxGet() {
    throw new ImplementationNeededError();
  }

  /**
   * Optionally add extra ajax parameters to the request for the data to cache.
   *
   * @protected
   */
  // eslint-disable-next-line no-unused-vars
  addAjaxParameters(ajaxRequestData) {
  }

  /**
   * Clear out the cache.
   */
  clearIdToOnLoadedFunctions() {
    throw new ImplementationNeededError();
  }

  /**
   * Call this method when the page loads to go load your typeaheadType.  Once everything is loaded, it'll call the
   * onLoaded method that you've passed in. If the options were previously loaded on this page, the onLoadedFunction will
   * be called immediately. Once your onLoaded method has been called, calling getOptionsFromCache will immediately return your data.
   *
   * @param [onLoadedFunction] {function} The callback function to invoke when objects are loaded from the backend
   * @param [requestData] {object} Extra QueryString parameters that will be passed in the Ajax call for loading the object cache options.
   * @param [requestData.includeAllVersions] {boolean} True if you want all versions included in the typeahead, false otherwise.
   * @param [requestData.includeAllApprovedVersions] {boolean} True if you want all approved versions included in the typeahead, false otherwise.
   * @param throwOnError {boolean} If true, the returned promise will throw an error. By default, it handles the errors automatically.
   * @return {Promisable} An object that can provide a promise that is complete once the data for all the data has
   * been loaded. The first argument will be the results.
   */
  loadOptions(onLoadedFunction, requestData, {throwOnError = false} = {throwOnError: false}) {
    this.initLoadingStatus();

    let returnPromise = this.waitForStorageToLoad(this.type).then(() => {
      Logger.debug(() => "BaseObjectCache :: loadOptions for " + this.type + ". Storage loaded");
      let options = this.getOptionsFromCache();
      Logger.debug(() => "BaseObjectCache :: loadOptions for " + this.type + ". Options", Log.object(options));
      let notAlreadyLoaded = !options || BaseObjectCache.isObjectCacheStillLoading(options);
      let forceReload = false;
      const typeCode = UIUtils.getTypeCodeForModelName(this.type);

      // Ensure the versions have been loaded
      if (!notAlreadyLoaded && requestData?.includeAllVersions) {
        forceReload = !options[Object.keys(options)[0]]?.allVersionsWithDetails;
      }
      if (!notAlreadyLoaded && requestData?.includeAllApprovedVersions) {
        forceReload = !options[Object.keys(options)[0]]?.approvedVersionsWithDetails;
      }

      if (onLoadedFunction) {
        this.addOnLoadedFunction(onLoadedFunction);
      }
      let loadingStatus = this.getLoadingStatus();

      if (loadingStatus === LOADING_STATUS.IDLE || forceReload) {
        this.setLoadingStatus(LOADING_STATUS.LOADING);
        let url = this.buildURLForAjaxGet();

        let ajaxRequestData = {
          approved: false,
          isCacheRequest: true,
          ...requestData,
        };
        this.addAjaxParameters(ajaxRequestData);

        return new Promise(resolve => {
          UIUtils.secureAjaxGET(url, ajaxRequestData, true, this.defaultFailFunction, false)
            .done(result => {
              this.setLoadingStatus(LOADING_STATUS.LOADED);
              this.handleTypeaheadResultsFromServer(result);
              resolve(result);
            });
        });
      } else if (loadingStatus === LOADING_STATUS.LOADING) {
        return this.waitForDataToLoad().then(() => {
          const options = this.getOptionsFromCache();
          if (onLoadedFunction) {
            onLoadedFunction(options, typeCode);
          }
          return options;
        });
      } else if (loadingStatus === LOADING_STATUS.LOADED) {
        // The options have already been loaded, possibly on a different page.
        if (onLoadedFunction) {
          onLoadedFunction(options, typeCode);
        }
        Logger.debug(() => "BaseObjectCache :: loadOptions for " + this.type + ". Results(3) ", Log.object(options));
        return options;
      } else {
        Logger.debug(() => "BaseObjectCache :: loadOptions for " + this.type + ". Results(4) ", Log.object(options));
        return options;
      }
    });

    // Makes sure errors are not ignored
    if (returnPromise && !throwOnError) {
      returnPromise = returnPromise.catch(error => UIUtils.defaultFailFunction(error));
    }

    const logPromise = new Promise((resolve) => {
      Logger.debug(() => "BaseObjectCache :: loadOptions for " + this.type + ". Cache is loaded " + BaseObjectCache.isStorageLoaded.toString());
      resolve(true);
    });

    return new Promisable(logPromise.then(() => returnPromise));
  }

  /**
   * Add a function to be called later when the data is loaded.
   *
   * @return {object} the idToOnLoadedFunctions that was modified.
   *
   * @protected
   */
  // eslint-disable-next-line no-unused-vars
  addOnLoadedFunction(onLoadedFunction, id = this.getId(), type = this.type) {
    let idToOnLoadedFunctions = this.getIdToOnLoadedFunctions(type);

    if (!idToOnLoadedFunctions[id]) {
      idToOnLoadedFunctions[id] = [];
    }

    if (onLoadedFunction) {
      idToOnLoadedFunctions[id].push(onLoadedFunction);
    }

    return idToOnLoadedFunctions;
  }

  defaultFailFunction(result) {
    Logger.debug(() => "BaseObjectCache :: defaultFailFunction", Log.object(result));
    if (result && result.responseJSON && result.responseJSON.code === 403) {
      this.invalidateCacheOptions();
    }

    UIUtils.defaultFailFunction(result);
  }

  handleTypeaheadResultsFromServer(result) {
    const id = this.getId();
    const typeCode = UIUtils.getTypeCodeForModelName(this.type);

    this.setCacheOptions(result);
    const idToOnLoadedFunctions = this.getIdToOnLoadedFunctions();
    const onLoadedFunctions = (idToOnLoadedFunctions && idToOnLoadedFunctions[id]) || [];
    for (let onLoadedFunction of onLoadedFunctions) {
      onLoadedFunction(result, typeCode);
    }
  }

  /**
   * Get the options from the cache.
   *
   * @return {([]|{})} An array of options for a typeahead or a single object for a particular ID.  If we're still
   * waiting on the data from the back end, the options returned will include a single record with a message to wait.
   */
  getOptionsFromCache() {
    const id = this.getId();
    let returnValue = this.getOptionsFromInMemory(id, this.getCacheName(), this.type);

    if (returnValue === null || returnValue === undefined) {
      returnValue = BaseObjectCache.getInitialOptions();
    }

    return returnValue;
  }

  initLoadingStatus(id = this.getId(), cacheName = this.getCacheName(), type = this.type) {
    let semaphore = loadingSemaphore[cacheName];
    if (!semaphore) {
      loadingSemaphore[cacheName] = {};
      semaphore = loadingSemaphore[cacheName];
    }

    if (!semaphore[type]) {
      semaphore[type] = {};
    }

    if (!semaphore[type][id]) {
      semaphore[type][id] = LOADING_STATUS.IDLE;
    }
  }

  resetLoadingStatus(id = this.getId(), cacheName = this.getCacheName(), type = this.type) {
    let semaphore = loadingSemaphore[cacheName];
    if (!semaphore) {
      loadingSemaphore[cacheName] = {};
      semaphore = loadingSemaphore[cacheName];
    }

    if (!semaphore[type]) {
      semaphore[type] = {};
    }

    semaphore[type][id] = LOADING_STATUS.IDLE;
  }

  setLoadingStatus(status, id = this.getId(), cacheName = this.getCacheName(), type = this.type) {
    loadingSemaphore[cacheName][type][id] = status;
  }

  getLoadingStatus(id = this.getId(), cacheName = this.getCacheName(), type = this.type) {
    return loadingSemaphore[cacheName][type][id];
  }

  getOptionsFromInMemory(id, cacheName, type) {
    const cacheObject = inMemoryCache[cacheName];
    if (!cacheObject) {
      return null;
    }

    let idToObjectOptions = cacheObject[type];
    let returnValue;
    if (idToObjectOptions) {
      returnValue = UIUtils.deepClone(idToObjectOptions[id]);
    } else {
      returnValue = null;
    }

    return returnValue;
  }

  setCacheOptions(options) {
    Logger.debug(() => "BaseObjectCache :: setCacheOptions for " + this.type, Log.object(options));
    this.setCacheOptionsHelper(this.getCacheName(), options);
  }

  /**
   * This is just to help the setCacheOptions method with setting archived vs non-archived data.
   */
  setCacheOptionsHelper(cacheName, options, type = this.type, id = this.getId(), onlyInMemory = false) {
    this.setCacheOptionsIntoMemory(cacheName, options, type, id);
    if (!onlyInMemory) {
      BaseObjectCache.persistInMemoryCacheToStorage(cacheName).then(() => {
      });
    }
  }

  setCacheOptionsIntoMemory(cacheName, options, type, id) {
    let objectToIdToOptions = inMemoryCache;
    if (!objectToIdToOptions[cacheName]) {
      objectToIdToOptions[cacheName] = {};
    }

    if (!objectToIdToOptions[cacheName][type]) {
      objectToIdToOptions[cacheName][type] = {};
    }
    objectToIdToOptions[cacheName][type][id] = options;
  }

  static async persistInMemoryCacheToStorage(cacheName) {
    const storageEngine = BaseObjectCache.getCacheStorageEngine();
    let objectToIdToOptions = inMemoryCache[cacheName];

    try {
      return await storageEngine.set(cacheName, objectToIdToOptions, null, "BaseObjectCache::persistInMemoryCacheToStorage");
    } catch (ex) {
      Logger.debug(() => "failed to set object in session storage", cacheName, Log.object(objectToIdToOptions));
      throw ex;
    }
  }

  /**
   * Call this method to invalidate cache options, so they're reloaded for the type passed into the constructor the next
   * time one of the load() * methods are called.
   */
  invalidateCacheOptions() {
    Logger.debug(() => "BaseObjectCache :: invalidateCacheOptions for " + this.type);
    this.clearIdToOnLoadedFunctions();
    return this.invalidateCacheHelper(this.getCacheName());
  }

  invalidateCacheOptionsAsync() {
    Logger.debug(() => "BaseObjectCache :: invalidateCacheOptionsAsync for " + this.type);
    this.clearIdToOnLoadedFunctions();
    return this.invalidateCacheHelperAsync(this.getCacheName());
  }

  invalidateCacheHelper(cacheName, type = this.type) {
    return this.invalidateCacheHelperAsync(cacheName, type);
  }

  async invalidateCacheHelperAsync(cacheName, type = this.type) {
    this.invalidateCacheFromInMemory(cacheName, type);
    this.resetLoadingStatus();
    return await BaseObjectCache.persistInMemoryCacheToStorage(cacheName);
  }

  invalidateCacheFromInMemory(cacheName, type) {
    MemoryCache.clearAllInstances();

    const id = this.getId();
    if (inMemoryCache[cacheName]) {
      let objectToIdToOptions = inMemoryCache[cacheName];
      if (objectToIdToOptions[type]) {
        if (id) {
          delete objectToIdToOptions[type][id];
        } else {
          delete objectToIdToOptions[type];
        }
      }
    }
  }

  async waitForDataToLoad() {
    const waitFunction = (resolve) => {
      let loadingStatus = this.getLoadingStatus();

      if (loadingStatus === LOADING_STATUS.LOADED) {
        Logger.debug(() => "BaseObjectCache :: waitForDataToLoad for " + this.type);
        resolve(true);
        return;
      }

      setTimeout(() => waitFunction(resolve), this.getRandomInt(200, 900));
    };

    return new Promise(resolve => waitFunction(resolve));
  }

  async waitForStorageToLoad(cacheKey) {
    const waitFunction = (resolve) => {
      if (BaseObjectCache.isStorageLoaded) {
        Logger.debug(() => "BaseObjectCache :: waitForStorageToLoad for " + cacheKey);
        resolve(true);
        return;
      }

      setTimeout(() => waitFunction(resolve), this.getRandomInt(200, 900));
    };

    return new Promise(resolve => waitFunction(resolve));
  }

  getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }
}
