"use strict";
/**
 * Thrown when something isn't validated correctly.
 * @summary THIS CLASS MUST NOT HAVE DEPENDENCY TO ANY OTHER CLASS (except the built-in Error class)
 */
class ValidationError extends Error {
  /**
   * Creates a new validation error with the specified message
   * @param {string} message The error message to display.
   */
  constructor(message, statusCode = 400) {
    super(message);

    // This was borrowed from https://gist.github.com/justmoon/15511f92e5216fa2624b
    Error.captureStackTrace(this, this.constructor);

    this.doNotSendStackTrace = true;
    this.name = this.constructor.name;
    this.message = message;
    this.isValidation = true;
    this.code = statusCode;
    this.statusCode = statusCode;
  }
}

module.exports.ValidationError = ValidationError;
