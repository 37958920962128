"use strict";
/**
 * The functions in this file are responsible for figuring out what browser we're in.
 *
 * Ideas for this were borrowed from: https://stackoverflow.com/questions/9847580/how-to-detect-safari-chrome-ie-firefox-and-opera-browser
 */

module.exports.Browser = {
  // We actually support these
  CHROME: "Chrome",
  FIREFOX: "Firefox",
  EDGE: "EDGE",
  // We don't officially support these, but try to play nice
  SAFARI: "Safari",
  INTERNET_EXPLORER: "Internet Explorer",
  OPERA: "Opera",
  UNKNOWN: "Unknown",
};

/* eslint-disable no-unused-vars, no-undef */
module.exports.detectBrowser = function() {
  if (typeof InstallTrigger !== "undefined") {
    return exports.Browser.FIREFOX;
  } else if (!(/*@cc_on!@*/false || !!document.documentMode) && !!window.StyleMedia) {
    return exports.Browser.EDGE;
  } else if (/constructor/i.test(window.HTMLElement) || (function(p) {
    return p.toString() === "[object SafariRemoteNotification]";
  })(!window["safari"] || (typeof safari !== "undefined" && safari.pushNotification))) {
    return exports.Browser.SAFARI;
  } else if (/*@cc_on!@*/false || !!document.documentMode) {
    return exports.Browser.INTERNET_EXPLORER;
  } else if((!!window.opr && !!opr.addons) || !!window.opera || navigator.userAgent.indexOf(" OPR/") >= 0) {
    return exports.Browser.OPERA;
  } else if (window.chrome) {
    return exports.Browser.CHROME;
  } else {
    return exports.Browser.UNKNOWN;
  }
};
/* eslint-enable no-unused-vars, no-undef */
