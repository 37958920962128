"use strict";

import {
  faArchive,
  faClone, faEdit,
  faEye, faTrash, faTrashRestore, faUsers
} from "@fortawesome/free-solid-svg-icons";
import proposeIcon from "../../images/propose.png";
import CommonUtils from "../../../server/common/generic/common_utils";
import CommonTraining from "../../../server/common/editables/common_training";
import RiskUtils from "../../../server/common/misc/common_risk_utils";

export const ACTION_TO_ICON_ENUM = {
  VIEW: {
    title: "View",
    icon: faEye
  },
  EDIT: {
    title: "Edit",
    icon: faEdit
  },
  PROPOSE: {
    title: "Propose",
    icon: proposeIcon
  },
  ARCHIVE: {
    title: "Archive",
    icon: faArchive
  },
  COPY: {
    title: "Copy",
    icon: faClone
  },
  DELETE: {
    title: "Delete",
    icon: faTrash
  },
  RESTORE: {
    title: "Restore",
    icon: faTrashRestore
  },
  USERS: {
    title: "Users",
    icon: faUsers
  },
  ADD_FROM_LIBRARY: {
    title: "Select from Library",
  },
  SEPARATOR: {
    title: "Separator",
  },
  SWAP_MATERIAL: {
    title: "Swap",
  },
  SYNC_MATERIAL: {
    title: "Sync",
  },
  UNLINK_MATERIAL: {
    title: "Unsync & Unlink",
  },
  UNSYNC_MATERIAL: {
    title: "Unsync & Keep Linked",
  },
  UNLINK_MATERIAL_ATTRIBUTE: {
    title: "Unlink",
  },
  LINK_MATERIAL_ATTRIBUTE: {
    title: "Link",
  },
};

/**
 * The name of the method used to perform a text diff
 * @enum {string}
 */
export const TEXT_DIFF_METHOD_ENUM = {
  whole: "whole",
  differential: "differential"
};

export const RISK_TYPE_ENUM = RiskUtils.RISK_TYPE_ENUM;

export const RISK_TYPE_EFFECTS = ["Adds", "Degrades", "Removes"];

export const TABLE_COLUMN_INDEX = {
  ID: 0,
  NAME: 1
};

export const EXPERIMENTS = CommonUtils.EXPERIMENTS;

export const FINAL_ATTRIBUTE_TYPE_CODES = ["FQA", "FPA"];

export const RISK_MAP_REPORT_FILTER_TYPE_ENUM = {
  Element: "Element",
  Risk: "Risk",
  SpecificElements: "SpecificElements",
  UnitOperations: "UnitOperations",
  Materials: "Materials",
};

export const TRAINING_TABS_ENUM = {
  Plans: "plans",
  Curricula: "curricula",
};

const ONBOARDING_STATUS = CommonTraining.ONBOARDING_STATUS;
export const ONBOARDING_STATUS_TO_NEXT_STATE = new Map([
  [ONBOARDING_STATUS.DISABLE_NOTIFICATIONS, "isConfirmationDisabled"],
  [ONBOARDING_STATUS.CONFIRM_ASSIGNMENT, "isUpdateAllDisabled"],
  [ONBOARDING_STATUS.UPDATE_ALL, "isEnableNotificationsDisabled"],
  [ONBOARDING_STATUS.ENABLE_NOTIFICATIONS, "isDisableNotificationsDisabled"],
]);

export const TRAINING_TABS = [
  {
    key: TRAINING_TABS_ENUM.Plans,
    title: "Plans",
    url: "/training/list.html",
  },
  {
    key: TRAINING_TABS_ENUM.Curricula,
    title: "Curricula",
    url: "/training/curricula/list.html",
  },
];

export const USER_TABS_ENUM = {
  Training: "training",
  Details: "details",
  Onboarding: "onboarding",
};

export const USER_TABS = [
  {
    key: USER_TABS_ENUM.Details,
    title: "Details",
    url: (params) => "/users/viewEdit.html?" + params,
  },
  {
    key: USER_TABS_ENUM.Training,
    title: "Training",
    url: (params) => "/training/myTraining.html?" + params,
  },
];

export const TRAINING_ONBOARDING_TABS = [
  {
    key: USER_TABS_ENUM.Onboarding,
    title: "Onboarding",
  },
];

export const EMPTY_STRING = "";

export const NEW_LINE = "\r\n";
