"use strict";

/**
* @typedef ILogStyle {{close: string, open: string}}
*/

/**
 * @type {ILogStyle}
 */
const LOG_STYLES = {
  /**
   * @type {ILogStyle}
   */
  reset: {open: "\u001b[0m", close: "\u001b[0m"},
  /**
   * @type {ILogStyle}
   */
  bold: {open: "\u001b[1m", close: "\u001b[22m"},
  /**
   * @type {ILogStyle}
   */
  dim: {open: "\u001b[2m", close: "\u001b[22m"},
  /**
   * @type {ILogStyle}
   */
  italic: {open: "\u001b[3m", close: "\u001b[23m"},
  /**
   * @type {ILogStyle}
   */
  underline: {open: "\u001b[4m", close: "\u001b[24m"},
  /**
   * @type {ILogStyle}
   */
  inverse: {open: "\u001b[7m", close: "\u001b[27m"},
  /**
   * @type {ILogStyle}
   */
  hidden: {open: "\u001b[8m", close: "\u001b[28m"},
  /**
   * @type {ILogStyle}
   */
  strikethrough: {open: "\u001b[9m", close: "\u001b[29m"},
  /**
   * @type {ILogStyle}
   */
  black: {open: "\u001b[30m", close: "\u001b[39m"},
  /**
   * @type {ILogStyle}
   */
  red: {open: "\u001b[31m", close: "\u001b[39m"},
  /**
   * @type {ILogStyle}
   */
  green: {open: "\u001b[32m", close: "\u001b[39m"},
  /**
   * @type {ILogStyle}
   */
  yellow: {open: "\u001b[33m", close: "\u001b[39m"},
  /**
   * @type {ILogStyle}
   */
  blue: {open: "\u001b[34m", close: "\u001b[39m"},
  /**
   * @type {ILogStyle}
   */
  magenta: {open: "\u001b[35m", close: "\u001b[39m"},
  /**
   * @type {ILogStyle}
   */
  cyan: {open: "\u001b[36m", close: "\u001b[39m"},
  /**
   * @type {ILogStyle}
   */
  white: {open: "\u001b[37m", close: "\u001b[39m"},
  /**
   * @type {ILogStyle}
   */
  gray: {open: "\u001b[90m", close: "\u001b[39m"},
  /**
   * @type {ILogStyle}
   */
  grey: {open: "\u001b[90m", close: "\u001b[39m"},
  /**
   * @type {ILogStyle}
   */
  bgBlack: {open: "\u001b[40m", close: "\u001b[49m"},
  /**
   * @type {ILogStyle}
   */
  bgRed: {open: "\u001b[41m", close: "\u001b[49m"},
  /**
   * @type {ILogStyle}
   */
  bgGreen: {open: "\u001b[42m", close: "\u001b[49m"},
  /**
   * @type {ILogStyle}
   */
  bgYellow: {open: "\u001b[43m", close: "\u001b[49m"},
  /**
   * @type {ILogStyle}
   */
  bgBlue: {open: "\u001b[44m", close: "\u001b[49m"},
  /**
   * @type {ILogStyle}
   */
  bgMagenta: {open: "\u001b[45m", close: "\u001b[49m"},
  /**
   * @type {ILogStyle}
   */
  bgCyan: {open: "\u001b[46m", close: "\u001b[49m"},
  /**
   * @type {ILogStyle}
   */
  bgWhite: {open: "\u001b[47m", close: "\u001b[49m"}
};

module.exports = {
  LOG_STYLES: LOG_STYLES,
};
