import React, { FC, useEffect, useState } from "react";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import style from "./NumericStepper.module.pcss";

type NumericStepperProps = {
  defaultValue?: number;
  step?: number;
  min?: number;
  max?: number;
  height?: number;
  onChange?: (newValue: number) => void;
};

const NumericStepper: FC<NumericStepperProps> = ({
  defaultValue = 0,
  step = 1,
  min = -Infinity,
  max = Infinity,
  height,
  onChange,
}) => {
  const [currentValue, setCurrentValue] = useState(0);
  const [shadowValue, setShadowValue] = useState<string | number>(0);

  useEffect(() => {
    setCurrentValue(defaultValue);
    setShadowValue(defaultValue);
  }, [defaultValue]);

  const handleIncrease = () => {
    handleChange(currentValue + step);
  };

  const handleDecrease = () => {
    handleChange(currentValue - step);
  };

  const handleChange = (newValue: number | string) => {
    const parsedValue = Number(newValue);
    const isValidNumber = !Number.isNaN(parsedValue);
    const inRange = parsedValue >= min && parsedValue <= max;

    if (!isValidNumber || !inRange) {
      setShadowValue(currentValue);
      return;
    }

    setShadowValue(parsedValue);
    setCurrentValue(parsedValue);
    onChange && onChange(parsedValue);
  };

  return (
    <div
      className={style.wrapper}
      {...(height && { style: { height: `${height}px` } })}
    >
      <button
        type="button"
        className={classNames(style.button, style.minusButton)}
        onClick={handleDecrease}
        data-testid="minus-button"
      >
        <FontAwesomeIcon className={style.icon} icon={faMinus} />
      </button>
      <input
        className={style.input}
        type="text"
        value={shadowValue}
        onChange={(event) => setShadowValue(event.currentTarget.value)}
        onBlur={(event) => handleChange(event.currentTarget.value)}
        onFocus={(event) => event.currentTarget.select()}
        data-testid="stepper-input"
      />
      <button
        type="button"
        className={classNames(style.button, style.plusButton)}
        onClick={handleIncrease}
        data-testid="plus-button"
      >
        <FontAwesomeIcon className={style.icon} icon={faPlus} />
      </button>
    </div>
  );
};

export default NumericStepper;
