"use strict";

/**
 *The functions in this file are responsible for exporting commonly used functions for what to do when things fail.
 */

import * as UIUtils from "../ui_utils";
import { extractErrorDetails } from "./ajax_wrapper";
import { ERROR_CATEGORY } from "../../server/common/generic/common_aggregate_error";
import { Log, LOG_GROUP } from "../../server/common/logger/common_log";

const Logger = Log.group(LOG_GROUP.Framework, "FailHandlers");

/**
 * This is the default failure handler an an Ajax call when we don't expect the server to respond with a 500 error.
 *
 *  NOTE: UIUtils.secureAjax* methods already do this internally.  No need to add it on extra.
 *
 *  @param result The error from the server
 *  @param [textStatus] This is the text version of the current status, usually "error" or similar. It's only function is to be logged to the console.
 *  @param [errorThrown] This is the error that was thrown. It's only function is to be logged to the console.
 *  @param [alertDiv] An optional parameter to specify where the error should go
 */
export function defaultFailFunction(result, textStatus, errorThrown, alertDiv) {
  if (!result || !result.isValidation || !result.alreadyLogged) {
    Logger.debug(() => "Unexpected failure occurred.  Result: ", Log.object(result), "Text status:", textStatus, "Error Thrown:", errorThrown, "Alert Div:", alertDiv);
  }

  let details = extractErrorDetails(result);

  if (details) {
    const shouldDisplayModalError = details.clientAction !== "save"
      && details.category !== ERROR_CATEGORY.LICENSE_UPGRADE_REQUIRED
      && (details.statusCode === 404 || details.statusCode === 403);

    if (shouldDisplayModalError) {
      const error = details.error || new Error(details.errorText);
      error.statusCode = details.statusCode;
      const title = details.statusCode === 404 ? "Not found" : "Access denied";
      UIUtils.displayErrorModal(`${error.statusCode} - ${title}`, details.errorText, {
        allowHide: false,
        hideBody: true
      });
      UIUtils.sendErrorTelemetry(error, details);
    } else {
      UIUtils.showError(details, result, alertDiv);
      details.alreadyLogged = true;
    }

    if (details && !details.alreadyLogged && (!details.error || !details.error.alreadyLogged)) {
      Logger.error(`Caught error: ${details.error ? details.error.message : details.errorText}`, () => details.error ? Log.error(details.error) : Log.object(details));
      details.alreadyLogged = true;
      if (details.error) {
        details.error.alreadyLogged = true;
      }
    }

    // Try to hide the loading screen, if it's still up.
    try {
      UIUtils.hideLoadingImage();
      UIUtils.setHideLoadingOnAjaxStop(true);
    } catch (error) {
      Logger.error("Caught this unexpected error while trying to hide the loading screen:", error);
    }
  }
}

/**
 * This is the failure handler you should use to set the isLoading state to false an an Ajax call. It extends the
 * defaultFailFunction above. To use it, bind it to your class so the `setStateSafely` method is available.
 *
 *  @param result The error from the server
 *  @param [textStatus] This is the text version of the current status, usually "error" or similar. It's only function is to be logged to the console.
 *  @param [errorThrown] This is the error that was thrown. It's only function is to be logged to the console.
 *  @param [alertDiv] An optional parameter to specify where the error should go
 */
export function defaultStopLoadingFailFunction(result, textStatus, errorThrown, alertDiv) {
  this.setStateSafely({isLoading: false});
  defaultFailFunction(result, textStatus, errorThrown, alertDiv);
}
