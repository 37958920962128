"use strict";
/*
 * This file provides an error you should throw in a parent class if a child class needs to implement it.
 *
 * Note: Server side in Node you should not follow this, but create something like HttpHelper.NoStackError (errors work different in NodeJS, unfortunately).
 */

export default class ImplementationNeededError extends Error {
  constructor(message = "You need to implement this in a derived class.") {
    super(message);
    this.name = "ImplementationNeededError";
  }
}
