"use strict";

import * as CommonUtils from "../../server/common/generic/common_utils";
import { Auth } from "aws-amplify";

/**
 * The functions in this file are responsible for providing Cognito helper methods
 */

export function configureUserPool() {
  const externalProviderLoginURL = `qbdvision-${CommonUtils.SUBDOMAIN}.auth.${CommonUtils.AWS_REGION}.amazoncognito.com`;
  const scopes = ["email", "openid", "profile", "aws.cognito.signin.user.admin"];
  Auth.configure({
    userPoolId: CommonUtils.COGNITO_POOL_ID,
    userPoolWebClientId: CommonUtils.COGNITO_CLIENT_ID,
    oauth: {
      domain: externalProviderLoginURL,
      redirectSignIn: `${CommonUtils.FRONT_END_URL}/`,
      responseType: "token",
      scope: scopes,
    }
  });
}

export function isCognitoErrorRetryable(err) {
  return err && ((err.statusText === "error" && err.status === 504)
    || (err.status === 500)
    || (err.code === "InternalErrorException")
    || (err.code === "NetworkError")
    || (err.code === "UnknownError"));
}

