"use strict";

import { Link, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import React, { createContext, useCallback, useContext } from "react";
import * as UIUtils from "../ui_utils";
import { RouterContext } from "./router_context";
import { preloadRouteComponent } from "./routes";

const NavigationContext = createContext({
  isBlocked: false
});

const clearPreventNavigationInRouterAndBrowser = (context) => {
  context.isBlocked = false;
  UIUtils.clearPreventNavigationForBrowser();
};

export const withRouter = (Component) => {
  return (props) => {
    const navigate = useCallback(useNavigate(), []);
    const {search} = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();
    let context = useContext(NavigationContext);

    const getParameterByName = useCallback((paramName) => {
      return search
        ? UIUtils.getParameterByName(paramName, "?", search)
        : UIUtils.getParameterByName(paramName);
    }, [search]);

    const customNavigate = useCallback((url) => {
      if (context.isBlocked) {
        const response = confirm(UIUtils.navigationWarning);
        if (response) {
          clearPreventNavigation();
          navigate(url);
        }
      } else {
        navigate(url);
      }
    }, []);

    const clearPreventNavigation = useCallback(() => {
      clearPreventNavigationInRouterAndBrowser(context);
    }, []);

    const preventNavigation = useCallback(() => {
      context.isBlocked = true;

      UIUtils.preventNavigationForBrowser();
    }, []);

    const setSearchParam = useCallback((paramName, paramValue) => {
      searchParams.set(paramName, paramValue);
      setSearchParams(searchParams);
    }, [searchParams, setSearchParams]);

    const removeSearchParam = useCallback((paramName) => {
      searchParams.delete(paramName);
      setSearchParams(searchParams);
    }, [searchParams, setSearchParams]);

    // properties on a component are required because we have consumers that require
    // the context to be available in the constructor. We need to refactor those components
    // to move the code that requires context from constructor to componentDidMount event
    return (
      <RouterContext.Provider
        value={{
          navigate,
          getParameterByName,
          preventNavigation,
          clearPreventNavigation,
          setSearchParam,
          removeSearchParam
        }}
      >
        <Component
          navigate={customNavigate}
          getParameterByName={getParameterByName}
          preventNavigation={preventNavigation}
          clearPreventNavigation={clearPreventNavigation}
          setSearchParam={setSearchParam}
          removeSearchParam={removeSearchParam}
          {...props}
        />
      </RouterContext.Provider>
    );
  };
};

export const QBDLink = (props) => {
  const context = useContext(NavigationContext);
  const onQbDLinkClick = (event) => {
    if (context.isBlocked) {
      const response = confirm(UIUtils.navigationWarning);
      if (!response) {
        event.preventDefault();
      } else {
        clearPreventNavigationInRouterAndBrowser(context);

        if (props.onClick) {
          props.onClick(event);
        }
      }
    } else if (props.onClick) {
      props.onClick(event);
    }
  };

  return (
    <Link onMouseEnter={() => preloadRouteComponent(props.to)} {...props} onClick={onQbDLinkClick}>
      {props.children}
    </Link>
  );
};
