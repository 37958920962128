"use strict";

import React from "react";
import {NavigateFunction} from "react-router-dom";

export type RouterContextType = {
  navigate: NavigateFunction;
  getParameterByName: (ignoredParamName: string) => string;
  preventNavigation: () => void;
  clearPreventNavigation: () => void;
  setSearchParam:
    (ignoredParamName: string, ignoredParamValue: string) => void;
  removeSearchParam: (ignoredParamName: string) => void;
}

/**
 * This context provides the router context to components
 */
export const RouterContext =
  React.createContext<RouterContextType>(null);
