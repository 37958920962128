"use strict";

const {ValidationError} = require("../../utils/errors/validation_error");
const CommonUtils = require("./common_utils");
const escape = require("escape-html");

/**
 * This file contains little helper functions for checking security that can be included in both the browser
 * and server side server files.
 */

module.exports.USER_DISABLED_MESSAGE = "User has been disabled. Please contact your Administrator for more information.";
module.exports.COGNITO_DEVELOPER_ERROR = "The Cognito user you are logged in as no longer exists in the database.";

module.exports.Departments = {
  CMC: "CMC",
  CLINICAL: "Clinical",
  FINANCE: "Finance",
  MANAGEMENT: "Management",
  OPERATIONS: "Operations",
  OTHER: "Other",
  QUALITY_ASSURANCE: "Quality Assurance",
  QUALITY_CONTROL: "Quality Control",
  RD: "R&D",
  REGULATORY: "Regulatory",
};

const DEPARTMENTS = new Set(Object.values(exports.Departments));

module.exports.Roles = {
  ADMINISTRATOR: "Administrator",
  SUPER_USER: "Super User",
  STANDARD_USER: "Standard User",
  REVIEWER_APPROVER: "Reviewer/Approver",
  VIEWER: "Viewer",
};

module.exports.UserLicenseTypes = {
  ESSENTIAL: "Essential",
  PROFESSIONAL: "Professional",
};

module.exports.CompanyLicenseTypes = {
  TRIAL: "trial",
  ESSENTIAL: "essential",
  MIXED: "mixed",
  PROFESSIONAL: "professional",
  INTERNAL: "internal",
  EDUCATIONAL: "educational",
  CANCELLED: "cancelled",
};

/**
 * @typedef SecurityActionType {"Add"|"Edit"|"Propose"|"Withdraw"|"Approve"|"Propose for Archive"|"Approve for Archive"|"Propose for Restore"|"Approve for Restore"|"Delete"|"Import"}
 */
/**
 * @typedef SecurityActionTypes {Object}
 * @property {SecurityActionType} PROPOSE
 * @property {SecurityActionType} APPROVE
 * @property {SecurityActionType} WITHDRAW
 *
 * @property {SecurityActionType} PROPOSE_FOR_ARCHIVE
 * @property {SecurityActionType} APPROVE_FOR_ARCHIVE
 *
 * @property {SecurityActionType} PROPOSE_FOR_RESTORE
 * @property {SecurityActionType} APPROVE_FOR_RESTORE
 *
 * @property {SecurityActionType} EDIT
 * @property {SecurityActionType} VIEW
 * @property {SecurityActionType} ADD
 * @property {SecurityActionType} DELETE
 *
 * @property {SecurityActionType} IMPORT
 * @property {SecurityActionType} CHANGE_CRITERIA
 */

/**
 * @type {SecurityActionTypes}
 */
module.exports.Actions = {
  VIEW: "View",
  ADD: "Add",
  BULK_ADD: "Bulk Add",
  EDIT: "Edit",
  PROPOSE: "Propose",
  WITHDRAW: "Withdraw",
  APPROVE: "Approve",
  PROPOSE_FOR_ARCHIVE: "Propose for Archive",
  APPROVE_FOR_ARCHIVE: "Approve for Archive",
  PROPOSE_FOR_RESTORE: "Propose for Restore",
  APPROVE_FOR_RESTORE: "Approve for Restore",
  DELETE: "Delete", // Delete a user
  IMPORT: "Import",
  CHANGE_CRITERIA: "Change Criteria",
};

/**
 * @typedef SecurityType {"Control Method"|"Document"|"FQA"|"FPA"|"IPA"|"IQA"|"General Attribute"|"Material"|"Material Attribute"|"Process Component"|"Process Parameter"|"Project"|"RMP"|"Supplier"|"TPP Section"|"Unit Operation"|"User"|"Company"|"ITP"|"Curriculum"|"Batch"|"Step"|"Library Material"}
 */
/**
 * @typedef SecurityTypes {Object}
 * @property {SecurityType} BATCH
 * @property {SecurityType} COMPANY
 * @property {SecurityType} CONTROL_METHOD
 * @property {SecurityType} CURRICULUM
 * @property {SecurityType} DOCUMENT
 * @property {SecurityType} FPA
 * @property {SecurityType} FQA
 * @property {SecurityType} GENERAL_ATTRIBUTE
 * @property {SecurityType} IPA
 * @property {SecurityType} IQA
 * @property {SecurityType} ITP
 * @property {SecurityType} MATERIAL
 * @property {SecurityType} MATERIAL_ATTRIBUTE
 * @property {SecurityType} PROCESS_COMPONENT
 * @property {SecurityType} PROCESS_PARAMETER
 * @property {SecurityType} PROJECT
 * @property {SecurityType} PROCESS
 * @property {SecurityType} RMP
 * @property {SecurityType} STEP
 * @property {SecurityType} SUPPLIER
 * @property {SecurityType} LIBRARY_MATERIAL
 * @property {SecurityType} TPP_SECTION
 * @property {SecurityType} UNIT_OPERATION
 * @property {SecurityType} USER
 */
/**
 * @type {SecurityTypes}
 */
module.exports.Types = {
  BATCH: "Batch",
  COMPANY: "Company",
  CONTROL_METHOD: "Control Method",
  CURRICULUM: "Curriculum",
  DOCUMENT: "Document",
  DOCUMENT_CONTENT: "Document Content",
  FPA: "FPA",
  FQA: "FQA",
  GENERAL_ATTRIBUTE: "General Attribute",
  IPA: "IPA",
  IQA: "IQA",
  ITP: "ITP",
  MATERIAL: "Material",
  MATERIAL_ATTRIBUTE: "Material Attribute",
  PROCESS_COMPONENT: "Process Component",
  PROCESS_PARAMETER: "Process Parameter",
  PROJECT: "Project",
  PROCESS: "Process",
  RMP: "RMP",
  STEP: "Step",
  SUPPLIER: "Supplier",
  LIBRARY_MATERIAL: "Library Material",
  TPP_SECTION: "TPP Section",
  UNIT_OPERATION: "Unit Operation",
  USER: "User",
};

//https://www.computerhope.com/issues/ch001789.htm
module.exports.VALID_FILE_EXTENSIONS = {
  Audio: [".aif", ".cda", ".mid", ".midi", ".mp3", ".mpa", ".ogg", ".wav", ".wma", ".wpl"],
  Compressed: [".7z", ".arj", ".deb", ".pkg", ".rar", ".rpm", ".tar.gz", ".z", ".zip"],
  Media: [".vcd", ".toast", ".iso", ".dmg", ".bin"],
  Data: [".csv", ".dat", ".db", ".dbf", ".log", ".mdb", ".sav", ".sql", ".tar", ".xml", ".dat"],
  Images: [".ai", ".bmp", ".ico", ".gif", ".jpeg", ".jpg", ".png", ".ps", ".psd", ".svg", ".tif", ".tiff"],
  Presentation: [".key", ".odp", ".pps", ".ppt", ".pptx"],
  Spreadsheets: [".ods", ".xlr", ".xls", ".xlsx"],
  Video: [".3g2", ".3gp", ".avi", ".flv", ".h264", ".m4v", ".mkv", ".mov", ".mp4", ".mpg", ".mpeg", ".rm", ".swf", ".vob", ".wmv"],
  Text: [".doc", ".docx", ".odt", ".pdf", ".rtf", ".tex", ".txt", ".wks", ".wks", ".wps", ".wpd", ".inp"],
  DesignOfExperiments: [".dxpx", ".jmp", ".sim", ".usp", ".mip", ".mat"],
  Google: [".gdoc", ".gslides", ".gsheet"],
};

/**
 * This function verifies that a department name is among the supported departments by the application. This is used in
 * most cases to verify the information submitted by the client.
 * @param department The department string to verify against legitimate departments.
 * @param throwError Set this to true if you want to throw an error. This is the default behavior.
 * @returns {*}
 */
module.exports.getSanitizedDepartmentName = function(department, throwError = true) {
  const result = DEPARTMENTS.has(department) ? department : null;

  if (result === null && throwError === true) {
    throw new ValidationError(`The specified department (${escape(department)}) does not exist`);
  }
  return result;
};

/**
 * This sanitised a literal so that it can be included in emails. Gmail and other mail providers would automatically
 * render email addresses and links as links the user can click on. This opens some security hole, where a potential
 * attacker could create a company name or a user with a username that renders in the recipients email as a
 * phishing site link.
 * @param literal The literal to sanitise.
 */
module.exports.getSafeLiteralForGmail = function(literal) {
  // https://stackoverflow.com/questions/11988534/prevent-gmail-from-creating-links-for-urls-and-email-addresses
  return escape(literal).replace(/([@.:])/g, "<span>$1</span>");
};

/**
 * This will determine if a file extension is valid based on the allowed file extensions for uploading documents and attachments.
 * @param path The path including the file name to verify
 */
module.exports.isFileNameValidForUpload = function(path) {
  let fileName = CommonUtils.getFileName(path);
  return fileName && exports.isFileExtensionAllowedForUpload(fileName);
};

/**
 * This will determine if a file extension is valid based on the allowed file extensions for uploading documents and attachments.
 * @param path The path including the file name to verify
 */
module.exports.isFileExtensionAllowedForUpload = function(path) {
  let validFileExtensions = Object.keys(exports.VALID_FILE_EXTENSIONS).reduce((extensions, extensionsPerCategory) => {
    return extensions.concat(exports.VALID_FILE_EXTENSIONS[extensionsPerCategory].map(extension => extension.toLowerCase()));
  }, []);
  let fileExtension = CommonUtils.getFileExtension(path);
  let fileName = CommonUtils.getFileName(path);

  return (!fileExtension && !fileName.startsWith("."))                //not providing a file extension is permitted as long as the filename does not start with a '.'
    || (fileExtension && validFileExtensions.includes(fileExtension)); //If a file extension is provided then it must be among the allowed ones.
};
