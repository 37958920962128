"use strict";

const {Log, LOG_GROUP} = require("../common/logger/common_log");

const Logger = Log.group(LOG_GROUP.ClientDB, "CacheMap");

/**
 * This class keeps a maximum number of items, along with a count of items requested over the past X seconds.
 *
 * Unfortunately, in JavaScript, there's no way to create a weak map like in Java/C#.
 * See https://stackoverflow.com/questions/25567578/garbage-collected-cache-via-javascript-weakmaps
 */
module.exports = class CacheMap {
  constructor(maxItems, secondsToKeepACountFor) {
    if (maxItems < 1) {
      throw new Error("Max items must be a positive integer");
    }
    if (secondsToKeepACountFor < 1) {
      throw new Error("Seconds to keep a count for must be a positive integer");
    }

    this.keyToCountsMap = new Map();
    this.internalMap = new Map();
    this.maxItems = maxItems;
    this.secondsToKeepACountFor = secondsToKeepACountFor;
  }

  get(key) {
    const value = this.internalMap.get(key);
    if (value) {
      this.keyToCountsMap.get(key).push(CacheMap.getCurrentTimeInSeconds());
    }
    return value;
  }

  has(key) {
    return this.internalMap.has(key);
  }

  static getCurrentTimeInSeconds() {
    return Math.floor(Date.now() / 1000);
  }

  set(key, value) {
    if (this.internalMap.has(key)) {
      this.internalMap.set(key, value);
    } else {
      if (this.internalMap.size === this.maxItems) {
        // Figure out who to kick out.
        let keys = this.internalMap.keys();
        let lowestKey;
        let lowestNum = null;
        let currentTime = CacheMap.getCurrentTimeInSeconds();
        for (let key of keys) {
          let totalCounts = this.keyToCountsMap.get(key);
          let countsSince = totalCounts.filter(count => count > (currentTime - this.secondsToKeepACountFor));
          this.keyToCountsMap.set(key, countsSince);
          if (lowestNum === null || countsSince.length < lowestNum) {
            lowestNum = countsSince.length;
            lowestKey = key;
          }
        }

        Logger.verbose(() => `Removing ${lowestKey} with count ${lowestNum} in order to add ${key}`);
        this.internalMap.delete(lowestKey);
      }
      this.internalMap.set(key, value);

    }

    // Count this as an access request.
    if (!this.keyToCountsMap.has(key)) {
      this.keyToCountsMap.set(key, [CacheMap.getCurrentTimeInSeconds()]);
    } else {
      this.keyToCountsMap.get(key).push(CacheMap.getCurrentTimeInSeconds());
    }
  }

  size() {
    return this.internalMap.size;
  }

  clear() {
    this.internalMap.clear();
    this.keyToCountsMap.clear();
  }
};
